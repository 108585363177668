<template>
    <div class="row w-100 p-4 g-0">
        <div class="col-lg-12 position-relative z-index-2">
            <div class="mb-4 card card-plain">
                <div class="p-3 card-body">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="d-flex flex-column h-100">
                                <h2 class="mb-0 font-weight-bolder">Dossiers Clients</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container-fluid">
                    <div class="col-4 mb-3">
                        <div class="input-group">
                            <span class="input-group-text text-body">
                                <i class="fas fa-search" aria-hidden="true"></i>
                            </span>
                            <input v-model="inputFilter" @keyup="filterUsers" type="text" class="form-control"/>
                        </div>
                    </div>
                    <div class="card h-100 p-4">
                        <div class="table-responsive">
                            <table class="table align-items-center mb-0">
                                <thead>
                                    <tr>
                                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Nom complet</th>
                                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Adresse email</th>
                                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Rattaché à</th>
                                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Site web</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-if="usersLoading" style="width:100%;" class="text-center">
                                        <td colspan="4">
                                            <div class="spinner-border" role="status">
                                                <span class="visually-hidden">Loading...</span>
                                            </div>
                                        </td>
                                    </tr> 
                                    <tr v-else v-for="(user, index) in filteredUsersList" :key="index">
                                        <td class="text-center ellipse medium">{{ user.fullName }}</td>
                                        <td class="text-center ellipse medium"><strong>{{ user.email }}</strong></td>
                                        <td class="text-center ellipse medium">{{ user?.companyInfo?.companyId?.name || 'Kernel' }}</td>
                                        <td class="text-center ellipse medium">{{ user.websiteUrl }}</td>
                                        <td class="text-center">
                                            <div class="d-flex justify-content-around align-items-center">
                                                <router-link :to="`/advisor/customer/${user._id}`">
                                                    <SoftButton>
                                                        <div>Voir</div>
                                                    </SoftButton>
                                                </router-link>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { onMounted, ref } from "vue"; 
    import axios from 'axios';
    import Cookies from "js-cookie";
    import SoftButton from "@/components/SoftButton.vue";


    const axiosConfig = {
        'Authorization': `Bearer ${Cookies.get('token')}`
    };

    const err = ref({
      message: "",
      status: false
    });

    const inputFilter = ref("");

    const usersLoading = ref(true);

    const usersList = ref([]);
    const filteredUsersList = ref([]);

    const getUsers = async () => {
        try {
            usersLoading.value = true;
            const response = await axios({
                method: "GET",
                url: `${process.env.VUE_APP_SERVER}/admin/users/get-all?rankLevel=client`,
                headers: axiosConfig
            });
            
            if (response.status === 200) {
                usersList.value = response.data;
                filteredUsersList.value = usersList.value;
            }
        } catch (error) {
            
        } finally {
            usersLoading.value = false;
        }
    };

    const filterUsers = () => {  
        const filteredUsers = usersList.value.filter((user) => {
            return user?.fullName.toLowerCase().includes(inputFilter.value.toLowerCase()) ||
                user.email?.toLowerCase().includes(inputFilter.value.toLowerCase()) ||
                user.websiteUrl?.toLowerCase().includes(inputFilter.value.toLowerCase());
        });
        filteredUsersList.value = filteredUsers;
    };

    onMounted(() => {
        getUsers();
    });

</script>
<style scoped>
    @import "../../../../../src/assets/css/tables-custom.css";
    .trash {
        margin-left: 10px;
    }
    .btn-secondary {
        padding: 0.25rem 1rem;
        font-size: 12px;
    }
    .table-responsive {
        max-height: 75vh;
        overflow: auto;
    }
    
</style>

