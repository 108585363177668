<template>
    <section id="dashboard-shortcuts">
        <div id="dashboard-shortcuts__top-line">
            <NavigationCard 
                v-if ="!hideChat"
                :method="openChat"
                :icon="PaperPlaneIcon" 
            >
                Parler à l'équipe
            </NavigationCard>  
            <NavigationCard 
                link="/natural-seo/traffic-statistics"
                :icon="StatisticsIcon"
                id="dashboard-shortcuts__top-line__statistics"
            > 
                <div id="dashboard-shortcuts__top-line__statistics__container">
                    <div id="dashboard-shortcuts__top-line__statistics__container__label">
                        Statistiques
                    </div>
                    <div id="dashboard-shortcuts__top-line__statistics__container__analytics">
                        <GoogleAnalyticsConnexionStatus />
                    </div>
                </div>
            </NavigationCard>  
        </div>
        <div id="dashboard-shortcuts__bottom-line">
            <NavigationCard 
                link="/natural-seo/seo-audit"
                :icon="AuditSeoIcon" 
            >
                Audit SEO
            </NavigationCard>
            <NavigationCard 
                link="/my-account/file-sharing"
                :icon="GoogleDriveIcon" 
            >
                Transmettre des fichiers
            </NavigationCard>
        </div>
    </section>
</template>
<script setup>
import GoogleAnalyticsConnexionStatus from "@/components/services/GoogleAnalyticsConnexionStatus.vue";
import NavigationCard from "@/components/base/cards/NavigationCard.vue";
import PaperPlaneIcon from "@/assets/img/icons/paper-plane.png";
import StatisticsIcon from "@/assets/img/icons/statistics.png";
import GoogleDriveIcon from "@/assets/img/icons/google-drive.png";
import AuditSeoIcon from "@/assets/img/icons/audit-seo.png";
import { openChat } from "@/services/intercomService.js";
import { onMounted, ref } from 'vue';
import Cookies from 'js-cookie';

const hideChat = ref(false);

onMounted(async () => {
    let companyInfo = Cookies.get("companyInformations");
    if (companyInfo) {
        companyInfo = JSON.parse(companyInfo);
        if (companyInfo?._id === "66def2013f2ef2e21c022ffd") {
            hideChat.value = true;
        }
    }
});
</script>
<style lang="scss" scoped>
#dashboard-shortcuts {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &__top-line, &__bottom-line {
        display: flex;
        flex-direction: row;
        justify-content: start;
        gap: 1rem;
        flex-wrap: wrap;
    }

    &__top-line {
        &__statistics {
            &__container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                height: 100%;
                width: 100%;
    
                &__label {
                    width: 100%;
                }

                &__analytics {
                    width: 100%;
                    height: 35%;
                }
            }
            
        }
    }
    
}
</style>